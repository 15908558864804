<template>
<div>
  <div class="container" v-for="(p,index) in infoList" :key="index" :class="{'dark':p.bg=='#F4F9FF','reverse':p.direction==true}">
    <div class="img"><img :src="p.iconSrc" /></div>
    <div class="content">
      <div class="icon"><img :src="p.src" /><span>{{p.iconTitle}}</span></div>
      <div class="title">{{p.title}}</div>
      <div class="bar"></div>
      <div class="des" v-for="(item,id) in p.desList" :key="id">
        {{item}}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Information",
  data() {
    return {
      //srca: require("@/assets/img/pic_01.png"),
    };
  },
  props: {
      infoList: Array
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";
.container {
  //width: vw(1600);
  height: vw(828);
  background: #ffffff;
  padding: vw(65) vw(76) vw(143);
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    display: block;
    width: vw(680);
    img {
      display: block;
      margin: 0 auto;
      width: vw(680);
      background-size: 100% 100%;
    }
  }
  .content {
    padding: 0 vw(77);
    .icon {
      font-size: vw(50);
      font-family: HelveticaNeue-Medium, HelveticaNeue;
      font-weight: 500;
      color: #2f9cf6;
    display: flex;
    align-items: center;
      img {
          width: vw(48);
          height: vw(48); 
      }
      span {
          margin-left: vw(16);
      }
    }
    .title {
      width: vw(526);
      //height: 64px;
      font-size: vw(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: vw(32);
      margin-top: vw(16);
    }
    .bar {
        width: vw(34);
        height: vw(3);
        background: #2F9CF6;
        margin-top: vw(28);
        margin-bottom: vw(30);
    }
    .des {
      font-size: vw(22);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: vw(32);
      margin-top: vw(14);
    }
  }
}
.dark {
    background: #F4F9FF;
}
.reverse {
    flex-direction: row-reverse;
}
@media screen and (max-width: 1080px) {
.container {
  width: minPx(1600);
  height: minPx(828);
  padding: minPx(65) minPx(76) minPx(143);
  .img {
    width: minPx(680);
    img {
      width: minPx(680);
    }
  }
  .content {
    padding: 0 minPx(77);
    .icon {
      font-size: minPx(50);
      img {
          width: minPx(48);
          height: minPx(48); 
      }
      span {
          margin-left: minPx(16);
      }
    }
    .title {
      width: minPx(526);
      font-size: minPx(32);
      line-height: minPx(32);
      margin-top: minPx(16);
    }
    .bar {
        width: minPx(34);
        height: minPx(3);
        margin-top: minPx(28);
        margin-bottom: minPx(30);
    }
    .des {
      font-size: minPx(22);
      line-height: minPx(32);
      margin-top: minPx(14);
    }
  }
}
}
@media screen and (min-width: 1601px) {
.container {
  //width: maxPx(1600);
  height: maxPx(828);
  padding: maxPx(65) maxPx(76) maxPx(143);
  .img {
    width: maxPx(800);
    img {
      width: maxPx(800);
    }
  }
  .content {
    padding: 0 maxPx(77);
    .icon {
      font-size: maxPx(50);
      img {
          width: maxPx(48);
          height: maxPx(48); 
      }
      span {
          margin-left: maxPx(16);
      }
    }
    .title {
      //width: maxPx(526);
      font-size: maxPx(32);
      line-height: maxPx(32);
      margin-top: maxPx(16);
    }
    .bar {
        //width: maxPx(34);
        height: maxPx(3);
        margin-top: maxPx(28);
        margin-bottom: maxPx(30);
    }
    .des {
      font-size: maxPx(22);
      line-height: maxPx(32);
      margin-top: maxPx(14);
    }
  }
}
}
</style>
